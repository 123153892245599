<template>
  <div class="main-body" v-loading="list_loading">
<!--    <el-button @click="handleAddContract">新增</el-button>-->

    <div style="display: flex;width: 100%;height: 100%;justify-content: space-between" >
      <div class="tab-list">
        <div v-if="contract_list.length>0" class="tab-contract_list">
          <div v-for="item in contract_list" :class="select_contract.contractId===item.contractId?'tab-item tab-item-active':'tab-item'" @click="handleSelectTab(item)">
            <div class="tab-item-text">{{item.contractName}}</div>
          </div>
        </div>
        <div class="tab-list-empty" v-else>
          <img src="../../static/emtry.png">
          <div class="emtry-info">暂无数据</div>
        </div>
        <el-button type="primary" class="add-btn" @click="handleAddContract" icon="el-icon-plus">新增合同</el-button>
      </div>
      <div class="edit-list" v-if="select_contract">
        <div class="left-qys">
          <el-form class="moduleForm">
            <el-form-item  class="first-item">
              <div class="form-label">公司主体</div>
              <el-select
                v-model="searchData.company"
                clearable
                filterable
                placeholder="选择公司主体"
                @change="handeChangeCompany">
                <el-option
                  v-for="item in companyList"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="first-item">
              <div class="form-label">合同模板</div>
              <el-select
                v-model="searchData.template"
                clearable
                filterable
                placeholder="选择合同模板"
                @change='handeChangeTemplate'>
                <el-option
                  v-for="item in templateList"
                  :key="item.id"
                  :label="item.name"
                  :disabled="item.disabled"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="first-item">
              <div class="form-label">最晚签署日期</div>
              <el-date-picker
                v-model="searchData.date"
                @change="handleChangeInput"
                value-format="yyyy-MM-dd"
                type="date"
                :picker-options="signDateUpData"
                placeholder="选择最晚签署日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <div class="form-label">合同名称</div>
              <el-input clearable v-model="searchData.contractName" @change="handleChangeInput" placeholder="请输入合同名称"></el-input>
            </el-form-item>
          </el-form>
          <div class="tool">
            <div class="tool-title">大写金额转换工具</div>
            <div class="tool-flex">
              <div class="tool-left">
                <el-input placeholder="请输入数字金额" maxlength="15" clearable @input="toRMBUppercase" v-model="money" class="input-money"></el-input>
              </div>
              <div class="tool-right" id="tool">
                <el-input placeholder="大写金额显示位置" v-model="money_big" class="input-with-select">
                </el-input>
                <el-button slot="append" type="primary" @click="copyValue">复制</el-button>
              </div>
            </div>
          </div>
          <div class="left-content">
            <div class="contract-title">参数</div>
            <div v-if="dataParametersList.length>0" style="height: calc(100% - 116px)" class="contact-content">
              <div class="contact">
                <div class="contact-info">
                  <el-form>
                    <el-form-item v-for="item in dataParametersList" :key="item.name">
                      <div v-if="item.type==='htmlText'" class="contact-form-item">
                        <div class="label">{{ item.name }}</div>
                        <div class="form-content">
                          <el-input
                            @change="handleChangeInput"
                            v-model="item.value" type="textarea"
                            :placeholder="'输入'+ item.name" clearable>
                          </el-input>
                        </div>
                      </div>
                      <div v-if="item.type==='text'" class="contact-form-item">
                        <div class="label">{{ item.name }}</div>
                        <div class="form-content">
                          <el-input
                            @change="handleChangeInput" v-model="item.value" :placeholder="'输入'+ item.name" clearable></el-input>
                        </div>
                      </div>
                      <div v-if="item.type==='radio'" class="contact-form-item">
                        <div class="label">{{ item.name }}</div>
                        <div class="form-content radio-padding">
                          <div v-for="i in item.options">
                            <el-radio
                              @change="handleChangeInput" v-model="item.value" :label="i.label" :value="i.label" border>{{ i.label }}</el-radio>
                          </div>
                        </div>
                      </div>
                      <div v-if="item.type==='checkbox'" class="contact-form-item">
                        <div class="label">{{ item.name }}</div>
                        <div class="form-content radio-padding">
                          <div v-for="i in item.options">
                            <el-checkbox
                              @change="handleChangeInput" :key="i.id" v-model="item.isCheck.find(o=>o.id===i.id).check" :label="i.label" :value="i.id" border>{{ i.label }}
                            </el-checkbox>
                          </div>
                        </div>
                      </div>
                      <div v-if="item.type==='person'" class="contact-form-item">
                        <div class="label">{{ item.name }}</div>
                        <div class="form-content">
                          <el-input @change="handleChangeInput" maxlength="18" v-model="item.value" :placeholder="'输入'+ item.name" clearable></el-input>
                        </div>
                      </div>
                      <div v-if="item.type==='select'" class="contact-form-item">
                        <div class="label">{{ item.name }}</div>
                        <div class="form-content">
                          <el-select
                            v-model="item.value"
                            clearable
                            filterable
                            :placeholder="'选择'+item.name"
                            @change="handleChangeInput">
                            <el-option
                              v-for="item in item.options"
                              :key="item.id"
                              :label="item.label"
                              :value="item.label">
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
            </div>
            <div v-else class="contract-emtry" style="height: calc(100% - 116px)">
              <div class="emtry-center">
                <img src="../../static/emtry.png">
                <div class="emtry-info">暂无数据</div>
              </div>
            </div>
            <div class="footer footer-fix">
              <el-button  @click="close">关闭</el-button>
              <!-- <el-button size="mini" type="primary" :loading="previewLoad" @click="preview">预览</el-button>-->
              <div class="operating-btn" v-if="select_contract.isSigned_already===0">
                <el-button  type="danger"  style="width: auto" @click="delContract">删除
                </el-button>
                <el-button type="primary" :loading="saveLoad" style="width: auto" @click="preview">保存并预览
                </el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="right-qys">
          <div class="contract-title">合同预览</div>
          <div v-if="pdfUrl" class="contact-content" style="height: calc(100% - 45px);position: relative;overflow-y: hidden">
            <div
              v-if="pdfLoad"
              v-loading="pdfLoad"
              class="loading"
              element-loading-spinner="el-icon-loading"
              element-loading-text="加载中,请稍等"></div>
            <!-- S 整页pdf -->
            <PdfViewer v-else :pdfUrl="pdfUrlpre" :accessToken="accessToken" />
            <!-- E 整页pdf -->
          </div>
          <div v-else class="pdfEmtry">
            <div class="emtry-center">
              <img src="../../static/emtry.png">
              <div class="emtry-info">暂无数据</div>
            </div>
          </div>
        </div>
      </div>
      <div class="contract-empty" v-else>
        <img src="../../static/emtry.png">
        <div class="emtry-info">暂无数据</div>
      </div>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import pdf from 'vue-pdf'
import {
  CreateStandardContract,
  GetCompanyentity,
  GetContractInfo,
  GetContractPreviewData,
  GetConttemplate,
  GetStandard,
  GetTemplateParametersList,
  RemoveContract
} from "@/api";
import {isInterceptorCode} from "@/common/commonFuncs";
import PdfViewer from '../unit/pdfViewer.vue';
export default {
  name: 'HomeView',
  components: {pdf,PdfViewer},
  data() {
    return {
      /*合同列表*/
      contract_list:[],//合同列表全部的
      un_save_contract:[],//未保存的合同列表
      select_contract:'',//默认选中的合同
      list_loading:false,//合同loading
      searchData: {//下拉列表数据选择回显
        company: '',
        template: '',
        date: '',
        contractName:'',
      },
      oldData_template:'',//旧的数据
      signDateUpData: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      radio: '',
      checked1: '',
      checked2: '',
      numPages: 1,//pdf页数
      pdfUrl: '',
      pdfLoad: false,//加载pdf状态
      showFirst: false,//是否显示第一个光标
      companyList: [],//公司主体
      templateList: [],//合同模板
      requestid: '',
      dataParametersList: [],//右侧参数列表
      codeBlur: false,//身份证号input聚焦状态
      previewLoad: false,//预览loading状态
      saveLoad: false,//保存loading状态
      money: '', // 数字金额
      money_big: '', // 金额大写
      /* 文件预览 */
      pdfUrlpre:'',
      accessToken:'',

      isFormDirty: false, // 标记表单是否被修改
      is_operate:true,//判断如果签署中和签署完成不可编辑和删除
    }
  },
  watch: {
    // 当数据变动时，标记表单为已修改
    // 监听 dataParametersList
    // dataParametersList: {
    //   deep: true, // 深度监听，监听数组内部值的变化
    //   handler(newValue) {
    //     if (newValue.some(i => i.value !== '')) {
    //       this.isFormDirty = true;
    //     }
    //   }
    // },
    // // 监听 searchData
    // searchData: {
    //   deep: true, // 深度监听，监听对象内部属性的变化
    //   handler(newValue) {
    //     if (Object.values(newValue).some(value => value !== '')) {
    //       this.isFormDirty = true;
    //     }
    //   }
    // }
  },
  methods: {
    // 关闭监听是否有内容
    handleBeforeUnload(event) {
      if (this.isFormDirty) {
        const message = "你有未保存的更改，确定要离开吗？";
        // 对大部分浏览器返回一个标准的提示框
        event.returnValue = message;
        return message; // 为了兼容性
      }
    },
    // 预览右侧数据
    preview() {
      let para = {
        requestid: this.requestid,//流程id
        companyName: this.searchData.company,//公司主体
        caregoryId: this.searchData.template,//合同模板
        templateParams: this.dataParametersList,//参数
        expireTime: this.searchData.date,//最晚签约时间
        contractId:this.select_contract.is_created?this.select_contract.contractId:"",//已创建的合同
        contractName:this.searchData.contractName//合同名称
      }
      if (!para.requestid) {
        this.$message.warning('流程ID不能为空')
        return
      }
      if (!para.companyName) {
        this.$message.warning('请选择公司主体')
        return
      }
      if (!para.caregoryId) {
        this.$message.warning('请选择合同模板')
        return
      }
      if (!para.expireTime) {
        this.$message.warning('最晚签署日期不能为空')
        return
      }
      if(!para.contractName){
        this.$message.warning('合同名称不能为空')
        return
      }
      let required = []
      let unRequired = []
      let regex = 11 && /^1[3456789]\d{9}$/
      let check_email = /^([a-zA-Z\d])(\w|\-)+@[a-zA-Z\d]+\.[a-zA-Z]{2,4}$/; // 邮箱正则判断
      required = para.templateParams.filter(i => i.required === true)
      unRequired = para.templateParams.filter(i => i.required === false)
      for (let i = 0; i < required.length; i++) {
        const item = required[i]
        if (item.type === 'htmlText') {
          if (item.value === '') {
            this.$message.warning('请输入' + item.name + '!')
            return
          }
        }
        if (item.type === 'text') {
          if (item.value === '') {
            this.$message.warning('请输入' + item.name + '!')
            return
          }
        }
        if (item.name === '手机' && item.value !== '') {
          if (!regex.test(item.value)) {
            this.$message.error('手机号格式不正确!')
            return
          }
        }
        if (item.name === '邮箱' && item.value !== '') {
          if (!check_email.test(item.value)) {
            this.$message.error('邮箱格式不正确!')
            return
          }
        }
        if (item.type === 'radio') {
          if (item.value === '') {
            this.$message.warning('请选择' + item.name + '!')
            return
          }
        }
        if (item.type === 'person') {
          if (item.value === '') {
            this.$message.warning('请输入' + item.name + '!')
            return
          }
        }
        if (item.type === 'person' && item.value) {
          if (!this.validateIDCard(item.value)) {
            this.$message.error('身份证号码格式不正确！')
            return
          }
        }
        if (item.type === 'passport') {
          if (item.value === '') {
            this.$message.warning('请输入' + item.name + '!')
            return
          }
        }
        if (item.type === 'passport' && item.value) {
          if (!this.valPassport(item.value)) {
            this.$message.error('护照号码格式不正确，请确保护照号码为有效的字母和数字组合！')
            return
          }
        }
        if (item.type === 'checkbox') {
          // 判断 多选选项状态中是否都为false
          let checkStatus = item.isCheck.every(obj => obj.check === false);
          if (checkStatus) {
            this.$message.warning('请选择' + item.name + '!')
            return
          }
        }
        if (item.type === 'select') {
          if (item.value === '') {
            this.$message.warning('请选择' + item.name + '!')
            return
          }
        }
      }
      for (let x = 0; x < unRequired.length; x++) {
        const unItem = unRequired[x]
        if (unItem.name === '手机' && unItem.value !== '') {
          if (!regex.test(unItem.value)) {
            this.$message.error('手机号格式不正确!')
            return
          }
        }
        if (unItem.name === '邮箱' && unItem.value !== '') {
          if (!check_email.test(unItem.value)) {
            this.$message.error('邮箱格式不正确!')
            return
          }
        }
        if (unItem.type === 'person' && unItem.value) {
          if (!this.validateIDCard(unItem.value)) {
            this.$message.error('身份证号码格式不正确！')
            return
          }
        }
        if (unItem.type === 'passport' && unItem.value) {
          if (!this.valPassport(unItem.value)) {
            this.$message.error('护照号码格式不正确，请确保护照号码为有效的字母和数字组合！')
            return
          }
        }
      }
      this.saveLoad = true
      CreateStandardContract(para).then(res => {
        if (res.code === 200) {
          this.$message.success('保存成功')
          this.saveLoad = false
          if(this.un_save_contract.length>0){
            if(this.select_contract.contractId===this.un_save_contract[0].contractId){
              this.un_save_contract=[]
            }
          }
          this.getContractInfoList()
          this.isFormDirty=false
        } else {
          if (!isInterceptorCode(res.code)) {
            if(res.msg==='1101-INVALID CONTRACT STATUS,合同状态不是草稿状态'){
              this.$alert('合同已提交审核，不支持编辑。',
                '提示', {
                  customClass: 'message-warn',
                  showClose: false,  // 不显示关闭按钮
                  type: 'warning',
                  callback:()=>{
                    console.log('关闭提示')
                  },
                });
            }else{
              this.$message.error(res.msg)
            }
            this.saveLoad = false
          }
        }
      }).catch((e) => {
        this.$message.error('服务器连接失败!')
        console.log(e)
        this.saveLoad = false
      })
    },
    // 关闭当前会话
    close() {
      window.close()
    },
    codeInputBlur(code) {
      this.codeBlur = false
      if (code.length === 0) {
        this.showFirst = false
      }
    },
    codeInputFocus(code) {
      this.codeBlur = true
      if (code.length === 0) {
        this.showFirst = true
      }
    },
    codeInputChange(code) {
      if (code.length > 0) {
        this.showFirst = false
      } else {
        this.showFirst = true
      }
    },
    handleChangeInput(){
      this.isFormDirty=true
    },
    // 新增合同
    handleAddContract(){
      let matchingContracts=[]
      if(this.contract_list.length>0){
       matchingContracts = this.contract_list.filter(contract => contract.is_created === false);
      }
      if(matchingContracts.length===1){
        alert("你有未保存的合同,暂时不能添加新的合同")
        return
      }else{
        this.searchData.date=""
        this.searchData.company=""
        this.searchData.template=""
        this.searchData.contractName=""
        this.money=""
        this.money_big=""
        this.dataParametersList=[]
        this.pdfUrl=""
        this.select_contract=""
        this.templateList=[]
        // 找到当前列表中最大的 contractId，然后加 1
        const newContractId = this.contract_list.length > 0
          ? Math.max(...this.contract_list.map(item => Number(item.contractId || 0))) + 1
          : 1;
        const newContract = {
          contractId: newContractId,
          contractName: "新合同",
          isSigned_already: 0,
          is_created: false,
        };
        // 添加新合同
        this.un_save_contract.push(newContract);
        this.contract_list = this.contract_list.concat(this.un_save_contract)
        this.select_contract=newContract
      }
    },
    // 选择合同
    handleSelectTab(item){
      if(this.isFormDirty){
        // this.getCheckInput('你有未保存的更改，确定要离开吗？')
        if (window.confirm('你有未保存的更改，确定要离开吗？')) {
          // 用户点击了“确定”
          this.isFormDirty=false
          this.searchData.date=""
          this.searchData.company=""
          this.searchData.template=""
          this.searchData.contractName=""
          this.money=""
          this.money_big=""
          this.dataParametersList=[]
          this.pdfUrl=""
          this.select_contract=item
          if(this.select_contract.is_created){
            // 获取合同这中的内容
            this.getContractPreviewList(item.contractId)
          }
        } else {
          return
        }
      }else{
        this.select_contract=item
        if(this.select_contract.is_created){
          // 获取合同这中的内容
          this.getContractPreviewList(item.contractId)
        }
      }
    },
    // 删除合同
    delContract(){
      let delIndex = this.contract_list.findIndex(i=>i.contractId===this.select_contract.contractId)
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        callback: (action) => {
          if (action === 'confirm') {
            // 如果当前的合同的未保存的未审核中的
            if(!this.select_contract.is_created){
              this.contract_list.splice(delIndex,1)
              if(this.select_contract.contractId===this.un_save_contract[0].contractId){
                this.searchData.date=""
                this.searchData.company=""
                this.searchData.template=""
                this.searchData.contractName=""
                this.money=""
                this.money_big=""
                this.dataParametersList=[]
                this.pdfUrl=""
                this.select_contract=""
              }
              this.un_save_contract=[]
              this.$message.success("删除成功")
              this.getContractInfoList()
            }else{
              let para={
                requestid:this.requestid,
                contractId:this.select_contract.contractId
              }
              // 掉删除接口 然后 刷新列表
              RemoveContract(para).then(res=>{
                if (res.code === 200) {
                  this.$message.success("删除成功")
                  // 刷新列表
                  this.getContractInfoList()
                } else {
                  if (!isInterceptorCode(res.code)) {
                    this.$message.error(res.msg)
                  }
                }
              }).catch(e=>{
                this.$message.error('服务器连接失败!')
              })
            }
          } else if (action === 'cancel') {
            console.log('用户点击了取消');
          }
        }
      });

      // this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning',
      //   callback:()=>{
      //     console.log('关闭提示')
      //   },
      // }).then(() => {
      //
      // })
    },
    // 改变公司主体
    handeChangeCompany(val) {
      if(val){
        this.getContractTemplate()
      }
      this.searchData.template=""
      this.isFormDirty=true
    },
    // 改变合同模板
    handeChangeTemplate(val) {

      if(val){
        this.getParameter()
        this.getstandardPdf()
      }else{
        this.dataParametersList=[]
        this.pdfUrl=""
      }
      this.isFormDirty=true
    },
    // 判断是否填了内容
    getCheckInput(text){
        if (window.confirm(text)) {
          // 用户点击了“确定”
          console.log("User confirmed.");
        } else {
          // 用户点击了“取消”或关闭了对话框
          console.log("User cancelled.");
        }
    },
    // 文件预览new
    getPdfPre(){
      let data={
        path:this.pdfUrl
      }
      this.pdfLoad = true
      this.$axios.post('https://file-preview.wmenzai.com/translate.php',data,{
        headers: {
          "Content-Type":"application/json"
        },
      }).then(res =>{
        // 请求成功状态为200说明添加成功
        if(res.data.code===200){
          this.pdfUrlpre=res.data.data.body.webofficeURL
          this.accessToken=res.data.data.body.accessToken
          this.pdfLoad = false
        }else {
          if (!isInterceptorCode(res.code)) {
            this.$message.error(res.message)
          }
          this.pdfLoad = false
        }
      }).catch(error =>{
        console.log(error)
        this.$message.error('服务器连接失败!')
        this.pdfLoad = false
      })
    },
    // 整个页面PDF效果
    getNumPages(url) {
      let loadingTask = pdf.createLoadingTask(url)
      loadingTask.promise.then(pdf => {
        this.numPages = pdf.numPages
        this.pdfLoad = false
      }).catch((err) => {
        this.pdfLoad = false
        this.$message.error('pdf加载失败!')
      })
    },
    // 获取公司主体下拉列表
    getCompany() {
      GetCompanyentity().then(res => {
        if (res.code === 200) {
          this.companyList = res.data
        } else {
          if (!isInterceptorCode(res.code)) {
            this.$message.error(res.msg)
          }
        }
      }).catch(() => {
        this.$message.error('服务器连接失败!')
      })
    },
    // 获取合同模板下拉列表
    getContractTemplate() {
      GetConttemplate({companyName: this.searchData.company}).then(res => {
        if (res.code === 200) {
          this.templateList = res.data.map(item => ({
            ...item,
            disabled: false
          }));
          if(this.contract_list.length>0){
            let is_save_contract_list=this.contract_list.filter(i=>i.is_created)
            if(is_save_contract_list.length>0){
              this.templateList = this.templateList.map(item => {
                // 检查是否需要禁用当前项
                const isDisabled =is_save_contract_list.some(o => o.caregoryId === item.id)
                  && item.id !== this.select_contract.caregoryId; // 当前选中的 id 不禁用
                // 更新 disabled 属性
                return { ...item, disabled: isDisabled };
              });
            }
          }
        } else {
          if (!isInterceptorCode(res.code)) {
            this.$message.error(res.msg)
          }
        }
      }).catch(() => {
        this.$message.error('服务器连接失败!')
      })
    },
    // 获取右侧侧制式pdf
    getstandardPdf() {
      let para = {
        requestid: this.requestid,//地址拦获取
        companyName: this.searchData.company,//下拉列表获取
        caregoryId: this.searchData.template,//下拉列表获取
      }
      if (para.companyName === '') {
        this.$message.warning('请选择公司主体')
        return
      }
      if (para.caregoryId === '') {
        this.$message.warning('请选择合同模板')
        return
      }
      GetStandard(para).then(res => {
        if (res.code === 200) {
          this.pdfUrl = res.msg
          this.getPdfPre()
        } else {
          if (!isInterceptorCode(res.code)) {
            this.$message.error(res.msg)
          }
          this.pdfUrl=""
        }
      }).catch(() => {
        this.$message.error('服务器连接失败!')
      })
    },
    // 获取左侧参数
    getParameter() {
      let para = {
        requestid: this.requestid,//地址拦获取
        companyName: this.searchData.company,//下拉列表获取
        caregoryId: this.searchData.template,//下拉列表获取
      }
      GetTemplateParametersList(para).then(res => {
        if (res.code === 200) {
          this.dataParametersList = res.data
        } else {
          if (!isInterceptorCode(res.code)) {
            this.$message.error(res.msg)
          }
          this.dataParametersList=[]
        }
      }).catch(() => {
        this.$message.error('服务器连接失败!')
      })
    },
    // 身份证号正则
    validateIDCard(idCard) {
      // 正则表达式
      const regExp = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;

      // 判断是否匹配正则表达式
      if (regExp.test(idCard) === false) {
        return false;
      }

      // 验证最后一位校验码
      if (idCard.length === 18) {
        const weights = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]; // 加权因子
        const checkCodes = '10X98765432'; // 校验码对应值
        let sum = 0;
        for (let i = 0; i < 17; i++) {
          sum += parseInt(idCard[i]) * weights[i];
        }
        const mod = sum % 11;
        const checkCode = checkCodes.charAt(mod);
        if (idCard.charAt(17).toUpperCase() !== checkCode) {
          return false;
        }
      }
      return true;
    },

    // 护照正则
    valPassport(idCard){
      const regExp = /^[A-Z]{1}[0-9]{8,9}$/;
      // 判断是否匹配正则表达式
      if (regExp.test(idCard) === false) {
        return false;
      }
    },
    // 人民币大写转换函数
    toRMBUppercase(num) {
      console.log(num)
      if(!num){
        this.money_big=''
        return
      }
      if (typeof num === 'string') {
        num = parseFloat(num.replace(/,/g, ''));
      }

      // 保证最多只有两位小数
      num = parseFloat(num.toFixed(2));

      // 定义人民币大写数字和单位
      const digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
      const unit = ['', '拾', '佰', '仟'];
      const sectionUnit = ['', '万', '亿', '兆'];
      const decimalUnit = ['角', '分'];

      // 处理整数部分和小数部分
      const [integerPart, decimalPart = ''] = num.toString().split('.');

      // 转换整数部分
      const integerToUpper = (integerStr) => {
        let result = '';
        let section = 0;
        let zeroCount = 0;

        for (let i = integerStr.length - 1; i >= 0; i--) {
          const digitIndex = parseInt(integerStr[i], 10);
          const position = (integerStr.length - 1 - i) % 4;

          if (digitIndex === 0) {
            zeroCount++;
          } else {
            if (zeroCount > 0) {
              result = '零' + result;
            }
            result = digit[digitIndex] + unit[position] + result;
            zeroCount = 0;
          }

          if (position === 0) {
            result = sectionUnit[section++] + result;
          }
        }

        return result.replace(/零+/g, '零').replace(/零$/, '') || '零';
      };

      // 转换小数部分
      const decimalToUpper = (decimalStr) => {
        return decimalStr
          .split('')
          .map((n, i) => (n === '0' ? '' : digit[parseInt(n)] + decimalUnit[i]))
          .join('') || '';
      };

      const integerUpper = integerToUpper(integerPart);
      const decimalUpper = decimalToUpper(decimalPart);

      // 拼接结果
      this.money_big= integerUpper + '元' + (decimalUpper || '整');
  },
    // 转换金额
    digitUppercase() {
      let n = this.money
      const fraction = ['角', '分'];
      const digit = [
        '零', '壹', '贰', '叁', '肆',
        '伍', '陆', '柒', '捌', '玖',
      ];
      const unit = [['元', '万', '亿'], ['', '拾', '佰', '仟']];
      const head = n < 0 ? '欠' : '';
      n = Math.abs(n);
      let s = '';
      for (let i = 0; i < fraction.length; i++) {
        s += (digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '');
      }
      s = s || '整';
      n = Math.floor(n);
      for (let i = 0; i < unit[0].length && n > 0; i++) {
        let p = '';
        for (let j = 0; j < unit[1].length && n > 0; j++) {
          p = digit[n % 10] + unit[1][j] + p;
          n = Math.floor(n / 10);
        }
        s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
      }
      this.money_big = head + s.replace(/(零.)*零元/, '元').replace(/(零.)+/g, '零').replace(/^整$/, '零元整');
    },
    // 复制
    copyValue() {
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(this.money_big).then(() => {
            this.$message.success('复制成功');
          }, () => {
            this.$message.error('复制失败');
          }
        )
      } else {
        // 创建text area
        const textArea = document.createElement('input')
        textArea.value = this.money_big
        // 使text area不在viewport，同时设置不可见
        document.getElementById("tool").appendChild(textArea)
        textArea.focus()
        textArea.select()
        return new Promise((resolve, reject) => {
          // 执行复制命令并移除文本框
          document.execCommand('copy') ? resolve() : reject(new Error('出错了'))
          textArea.remove()
        }).then(() => {
            this.$message.success('复制成功');
          }, () => {
            this.$message.error('复制失败');
          }
        )
      }
    },
    // 查看当前合同数据
    getContractPreviewList(contractId) {
      let para={
        requestId: this.requestid,//流程id
        contractId:contractId
      }
      GetContractPreviewData(para).then(res => {
        if (res.code === 200) {
          this.searchData.company = res.data.companyName
          if (this.searchData.company) {
            this.getContractTemplate()
          }
          this.searchData.template = res.data.caregoryId
          this.oldData_template=res.data.caregoryId
          if (this.searchData.template) {
            this.getParameter()
            this.getstandardPdf()
          }
          this.searchData.date = res.data.expireTime
          this.searchData.contractName= res.data.contractName

        } else {
          if (!isInterceptorCode(res.code)) {
            this.$message.error(res.msg)
          }
        }
      }).catch(() => {
        this.$message.error('服务器连接失败!')
      })
    },
    // 合同列表+保存并预览的刷新列表
    getContractInfoList(){
      this.list_loading=true
      GetContractInfo({requestId:this.requestid}).then(res => {
        if (res.code === 200) {
          this.list_loading=false
          this.contract_list = res.data.map(item => ({
            ...item,
            is_created: true
          }));
          if(this.un_save_contract.length===1){
            this.contract_list=this.contract_list.concat(this.un_save_contract)
          }
          if(this.contract_list.length>0){
            this.select_contract=this.contract_list[0]
            this.getContractPreviewList(this.select_contract.contractId)
          }else{
            this.searchData.date=""
            this.searchData.company=""
            this.searchData.template=""
            this.searchData.contractName=""
            this.money=""
            this.money_big=""
            this.dataParametersList=[]
            this.pdfUrl=""
            this.select_contract=""
          }

        } else {
          if (!isInterceptorCode(res.code)) {
            this.$message.error(res.msg)
          }
          this.list_loading=false
        }
      }).catch(() => {
        this.$message.error('服务器连接失败!')
        this.list_loading=false
      })
    },
  },
  created() {
    this.getCompany()//获取公司主体下拉
    this.requestid = this.$route.query.requestId ? this.$route.query.requestId : ''
    if (this.requestid) {
      this.getContractInfoList()
    }
  },
  mounted() {
    // 监听浏览器的 beforeunload 事件
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  },
  // beforeDestroy() {
  //   // 在组件销毁时移除事件监听器
  //   window.removeEventListener("beforeunload", this.handleBeforeUnload);
  // },
}
</script>
<style scoped>
.main-body {
    display: block;
}
.edit-list{
    justify-content: space-between;
}
.left-qys{
    width: calc(50% - 25px);
}
.right-qys{
    width: calc(50% + 25px)!important;
    padding: 30px 30px 30px 50px!important;
}
.main-body {
    padding: 0;
    height: 100vh;
    width: 100%;
}
.last-item > div:last-child {
    border-bottom: none;
}

/*.contact {*/
/*    width: 700px;*/
/*}*/

.contact-info:last-child .contact-form-item {
    border-bottom: 0;
}

.contact-form-item {
    width: 100%;
    min-height: 50px;
    align-items: stretch;
    display: flex;
    font-weight: 400;
    font-size: 14px;
    color: #606266;
}

.label {
    min-height: 50px;
    background: #F4F4F4;
    min-width: 116px;
    flex: 1;
    text-align: right;
    line-height: 50px;
    padding-right: 13px;
    margin-right: 15px;
    border-right: 1px solid #DCDFE6;
}

.form-content {
    width: calc(100% - 145px);
    display: flex;
    min-height: 50px;
}

.form-content >>> .el-radio__input.is-checked .el-radio__inner {
    border-color: #57C5F7;
    background: #57C5F7;
}

.form-content >>> .el-radio.is-bordered.is-checked {
    border-color: #57C5F7;
}

.form-content >>> .el-radio.is-bordered {
    padding: 8px 10px;
    height: 30px;
}

.form-content >>> .el-radio__input.is-checked + .el-radio__label {
    color: #57C5F7;
}

.form-content >>> .el-radio {
    margin-right: 10px;
}

.form-content >>> .el-radio.is-bordered + .el-radio.is-bordered {
    margin-left: 0px;
}

.form-content >>> .el-checkbox__inner::after {
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background-color: #FFF;
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) !important;
    transition: transform .15s ease-in;
}

.form-content >>> .el-checkbox__inner {
    border-radius: 50%;
}

.form-content >>> .el-checkbox__inner:hover {
    border-color: #57C5F7;
}

.form-content >>> .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background: #57C5F7;
    border-color: #57C5F7;
    border-radius: 50%;
    position: relative;
}

.form-content >>> .el-checkbox.is-bordered {
    padding: 5px 10px;
    height: 30px;
    box-sizing: border-box;
}

.form-content >>> .el-checkbox.is-checked {
    border-color: #57C5F7;
}

.form-content >>> .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #57C5F7;
}

.form-content >>> .el-checkbox {
    margin-right: 10px;
}

.form-content >>> .el-button + .el-button, .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 0;
}

.form-content >>> .el-input__inner {
    height: 50px;
}

.box-code-info {
    position: relative;
    display: flex;
    padding: 10px 15px 10px 0;
    box-sizing: border-box;
    flex-wrap: wrap;
}

.box-code {
    border-top: 1px solid #DCDFE6;
    border-bottom: 1px solid #DCDFE6;
    border-left: 1px solid #DCDFE6;
    height: 28px;
    width: 28px;
    line-height: 30px;
    text-align: center;
    transition: all 0.3s;
    position: relative;
}

.box-code-info > div:last-child {
    border-right: 1px solid #DCDFE6;
}

.input-code {
    width: 540px;
    height: 30px;
    position: absolute;
    left: 0px;
    top: 10px;
    box-sizing: border-box;
    color: transparent;
    background-color: transparent;
    opacity: 0;
}

.form-content >>> .el-input--suffix .el-input__inner {
    width: 100%;
    height: 100%;
}
.form-content >>> .el-select .el-input--suffix{
    margin-top: 2px;
}

.form-content >>>.el-textarea__inner{
    padding: 14px 15px 5px 15px;
    font-family: PingFangSC, PingFang SC;
    height: 80px;
    min-height: 50px!important;
}
.form-content >>> .el-select {
    width: 100% !important;
}

.form-content >>> .el-select > .el-input {
    height: 46px;
}

.form-content >>> .el-textarea__inner,
.form-content >>> .el-input__inner {
    border: none;
    padding-left: 0;
}
.form-content >>> .el-checkbox__label{
    padding-left: 6px;
}
.radio-padding {
    /*height: 50px;*/
    line-height: 50px;
    flex-wrap: wrap;
}

.loading {
    height: 45px;
    position: absolute;
    top: 50%;
    width: 200px;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 36px;
}
.loading >>> .el-loading-mask .el-loading-text {
    font-size: 28px; /* 根据需要调整大小 */
}
.holder {
    width: 2px;
    height: 22px;
    background: #b4b4b4;
    animation: holderSnick 600ms infinite alternate;
    display: none;
    position: absolute;
    top: 3px;
    left: 42px;
}

.emtry-info {
    font-size: 14px;
    color: #606266;
    line-height: 20px;
    height: 20px;
}

.contact-info >>> .el-form-item {
    margin-bottom: 0;
    border-top: 1px solid #DCDFE6;
    border-left: 1px solid #DCDFE6;
    border-right: 1px solid #DCDFE6;
}

.contact-info >>> .el-form-item:nth-child(1) {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.contact-info >>> .el-form-item:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: 1px solid #DCDFE6;
}

@keyframes holderSnick {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.right-qys {
    background: #FFFFFF;
    width: 50%;
    height: 100%;
    padding: 30px 30px 30px 20px;
    box-sizing: border-box;
    border-radius: 2px;
}

.form-content::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.form-content::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 3px;
}

.form-content::-webkit-scrollbar-track {
    background: #F9F9F9;
    border: 1px solid #ECEEF5;
}

.footer-fix >>> .el-button--primary {
    background: #57C5F7;
    border: none;
    color: #FFFFFF !important;
}
.footer-fix >>> .el-button--danger {
    background: #F56C6C;
    border: none;
    color: #FFFFFF !important;
}

.footer-fix >>> .el-button {
    padding: 10px 15px;
    height: 40px;
}

.tool {
    background-color: #FFF;
    padding: 20px 0 30px 30px;
    border-top: 1px solid #F1F3F6;
}

.tool .tool-title {
    font-weight: 600;
    font-size: 18px;
    color: #333333;
    line-height: 28px;
    height: 28px;
    margin-bottom: 10px;
}

.tool .tool-flex {
    display: flex;
}

.tool .tool-left {
    width: 200px;
    margin-right: 10px;
}

.tool .tool-right {
    flex-grow: 1;
    width: calc(100% - 230px);
    display: flex;
}
.tool-right >>> .el-button--primary{
    background: #57C5F7;
    border:none;
    color: #FFFFFF !important;
    padding: 10px 15px;
    height: 40px;
    box-sizing: border-box;
    margin-left: 10px;
}
.tab-list >>> .el-button--primary {
    background: #57C5F7;
    border-color: #57C5F7;
    color: #FFFFFF !important;
}
.moduleForm{
    padding: 30px 0 10px 30px;
    box-sizing: border-box;
}
.moduleForm >>>.el-form-item{
    margin-right: 60px;
    width: calc(50% - 30px);
}
.moduleForm >>>.el-form-item:nth-child(2n){
  margin-right: 0;
}

.moduleForm >>> .el-select,.moduleForm >>> .el-input__inner,.moduleForm >>>.el-form-item__content,.moduleForm >>>.el-date-editor.el-input, .el-date-editor.el-input__inner{
    width: 100%;
}

.form-label{
  margin-bottom: 8px;
}

.moduleForm >>>.el-date-editor .el-input__suffix{
    right: 30px;
}
.moduleForm >>>.el-input__prefix{
    right: 5px;
    left: unset;
}
.moduleForm .first-item{
    margin-bottom: 20px;
}
.moduleForm >>>.el-select .el-input__inner{
    padding: 0 30px 0 10px;
}

.moduleForm >>>.el-input__inner{
    padding: 0 10px;
    border-radius: 4px;
    border-left: 1px solid #DCDFE6;
}
.moduleForm >>>.el-select .el-input__inner:focus,
.moduleForm >>>.el-input__inner:focus{
    border-color: #409EFF
}
.left-content{
    margin: 0;
    border-top:1px solid #F1F3F6;
    height: calc(100% - 343px);
    padding: 20px 0 30px 30px;
}
.footer{
    padding: 0;
}
.operating-btn{
    display: inline-block;
    margin-left: 10px;
}
</style>
