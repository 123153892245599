<template>
    <div id="web" style="width: 100%; height: 100%;"></div>
</template>

<script>
export default {
  props: {
    pdfUrl: {
      type: String,
      required: true
    },
    accessToken: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      scriptsLoaded : false
    };
  },
  mounted() {
    Promise.all([
      this.loadScript('https://g.alicdn.com/IMM/office-js/1.1.15/aliyun-web-office-sdk.min.js'),
      this.loadScript('https://gosspublic.alicdn.com/aliyun-oss-sdk-6.18.0.min.js')
    ])
      .then(() => {
        this.scriptsLoaded = true;
        this.loadPdf();
      })
      .catch(err => {
        console.error('Failed to load SDK scripts:', err);
      });
  },
  methods: {
    loadScript(url) {
      return new Promise((resolve, reject) => {
        if (document.querySelector(`script[src="${url}"]`)) {
          resolve(); // Script already loaded
          return;
        }
        const script = document.createElement('script');
        script.src = url;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Script load error for ${url}`));
        document.head.appendChild(script);
      });
    },
    loadPdf() {
      if (this.scriptsLoaded && typeof aliyun !== 'undefined') {
        try {
          let ins = aliyun.config({
            mount: document.querySelector("#web"),
            url: this.pdfUrl,
          });
          ins.setToken({
            token: this.accessToken,
          });
          console.log(ins, "First instance (ins)");
          console.log('Aliyun Office SDK 配置成功');
        } catch (error) {
          console.error('Aliyun Office SDK 配置失败:', error);
        }
      } else {
        console.error('aliyun 对象未定义，请检查 SDK 是否正确加载');
      }
    }
  }
}
</script>

<style scoped>
/* 添加一些基本的样式 */
</style>
